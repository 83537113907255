<template>

  <div>

    <div class="d-flex justify-content-center h-100 content">
      <b-row class="w-100" style="max-width: 1200px;">

        <b-col md="12" class="text-center mb-4">
          <b-img
            fluid
            class="mt-4 fixed-ops-logo"
            src="@/assets/fixed_ops_balance_forward_match_logo.jpg"
          />
          <p style="font-size: 22px; font-weight: 600; margin-top: 0px; margin-top: 10px;">
            <b-badge
              variant="danger">
              Enrollment in the Fixed Ops Co-Op Pilot ended August 31st, 2021
            </b-badge>
            </p>
        </b-col>

        <b-col md="8" class="mt-4">
          <h1>Rev Up Your Parts and Service After Sale Marketing!</h1>

          <div class="faq-item">
            <a @click.prevent="changeFaq(0)" class="no-select d-flex" style="padding-left: 40px;">
              <b-icon-chevronDown v-show="activeFaq == 0" class="faq-chevron"></b-icon-chevronDown>
              <b-icon-chevronRight v-show="activeFaq != 0" class="faq-chevron"></b-icon-chevronRight>
              <strong>Co-Op Benefits</strong>
            </a>
            <div v-show="activeFaq == 0">
              <img
                class="mt-2"
                style="width: 100%; padding-left: 35px; margin-bottom: 10px;"
                src="@/assets/google_graph.png"
              />
              <p style="padding-left: 35px;">Today, according to Google data, nearly 25% of all automotive searches are parts, service, and maintenance related. But dealerships are only showing up in search results one in every 20 results. This leaves huge opportunity for your dealership to increase reach, and ultimately book more service appointments and sell more parts. Plus, during the time this program is running, Honda is offering a 1:1 Co-Op Reimbursement for dollars spent up to $2000.</p>
            </div>
          </div>

          <div class="faq-item mt-4">
            <a @click.prevent="changeFaq(1)" class="no-select d-flex" style="padding-left: 40px;">
              <b-icon-chevronDown v-show="activeFaq == 1" class="faq-chevron"></b-icon-chevronDown>
              <b-icon-chevronRight v-show="activeFaq !== 1" class="faq-chevron"></b-icon-chevronRight>
              <strong>How does this program work?</strong>
            </a>
            <div v-show="activeFaq == 1">
              <ul>
                <li>Dealers must be enrolled in Honda Digital Solutions Program</li>
                <li>Enroll in Honda Digital Solutions Dig Ad Services by August 31st, 2021</li>
                <li>Co-Op Reimbursement is available to the first 250 Honda Digital Solution dealers to enroll in the Co-Op</li>
                <li>Increase Fixed Ops Digital Advertising baseline spend in Google Paid Search campaigns by $2,000 between July 1st, 2021 - December 31st, 2021</li>
                <li>Run Fixed Ops Paid Search ads for 90 days or more to qualify</li>
                <li>Receive $1 for $1 Co-Op Reimbursement via your Balance Forward Parts & Service statement up to $2,000 total over the Co-op timeframe | Look for line item: “Fixed Ops Paid Search Co-Op Match" at the end of the Co-Op program</li>
              </ul>
            </div>
          </div>

          <div class="faq-item" style="margin-top: 25px;">
            <a @click="changeFaq(2)" class="no-select d-flex" style="padding-left: 40px;">
              <b-icon-chevronDown v-show="activeFaq == 2" class="faq-chevron"></b-icon-chevronDown>
              <b-icon-chevronRight v-show="activeFaq !== 2" class="faq-chevron"></b-icon-chevronRight>
              <strong>How is this billed?</strong>
            </a>
            <div v-show="activeFaq == 2">
              <ul>
                <li>Dealers are directly billed from their Digital Advertising providers and reimbursed through their Balance Forward Parts & Service statement upon completion of Paid Search spend in Google campaigns. The line-item description on the Balance Forward statement will be: “Fixed Ops Paid Search Co-Op Match”.</li>
                <li>The Co-Op reimbursement will be reflected on the Balance Forward statement at the end of the Co-Op program.</li>
                <li>Reimbursement is not to exceed $2,000 in total from July 1st to December 31st, 2021.</li>
              </ul>
            </div>
          </div>

          <div class="faq-item" style="margin-top: 25px;">
            <a @click="changeFaq(3)" class="no-select d-flex" style="padding-left: 40px;">
              <b-icon-chevronDown v-show="activeFaq == 3" class="faq-chevron"></b-icon-chevronDown>
              <b-icon-chevronRight v-show="activeFaq !== 3" class="faq-chevron"></b-icon-chevronRight>
              <strong>How do I enroll?</strong>
            </a>
            <div v-show="activeFaq == 3">
              <p style="text-align: left; margin-left: 40px; margin-top: 10px; margin-bottom: 30px; font-weight: 400;">
                Enrollment has ended.
              </p>
            </div>
          </div>

          <div class="faq-item" style="margin-top: 25px;">
            <a @click="changeFaq(4)" class="no-select d-flex" style="padding-left: 40px;">
              <b-icon-chevronDown v-show="activeFaq == 4" class="faq-chevron"></b-icon-chevronDown>
              <b-icon-chevronRight v-show="activeFaq !== 4" class="faq-chevron"></b-icon-chevronRight>
              <strong>What are the co-op terms?</strong>
            </a>
            <div v-show="activeFaq == 4">
              <ul v-show="activeFaq == 4">
                <li>
                  By enrolling in the Co-Op Fixed Ops pilot, you grant Google permission to share your Parts & Service conversion metrics with Autodata and Honda. Please reference EULA for additional info.
                  <a
                    @click="openExternalLink('https://hondaprogram.com/files/fixed_ops_signup_pdf_default_1623781193.pdf')"
                    style="color: blue; text-decoration: underline;"
                    href="#">
                    Please reference EULA for additional info
                  </a>.
                </li>
                <li>Dealers enrolling after August 31st may or may not be eligible as the Co-Op Reimbursement is based on available Co-Op funds at the time of enrollment.</li>
                <li>Co-Op reimbursement is available to the first 250 Honda Digital Solution dealers to enroll</li>
                <li>Reimbursement will be paid out at the end of the Co-Op pilot</li>
              </ul>
            </div>
          </div>
        </b-col>

        <b-col md="4">
          <b-img
            fluid
            class="mt-2"
            style="border-radius: 20px;"
            src="@/assets/mechanic.jpg"
          />

        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>

export default {
  name: 'fixed-ops-signup',
  components: {
  },

  metaInfo: {
    title: 'Fixed Ops Signup'
  },

  data() {
    return {
      activeFaq: 0,
      form: {
        name: '',
        role: null,
        dealership_name: '',
        dealership_code: '',
        phone: '',
        email: '',
        consent: false,
        origin: 'fixed_ops_signup',
        ipAddress: '',
      },
      providers: [{ text: '-- select your provider --', value: null, selected: true, disabled: true, hidden: true }, 'C-4 Analytics', 'Dealer.com', 'Dealer eProcess', 'Dealer Inspire', 'DealerOn', 'PureCars', 'Stream Companies', 'Team Velocity'],
      validation: {
        name: null,
        providers: null,
        dealership_name: true,
        dealership_code: true,
        phone: null,
        email: null,
        consent: false,
      }
    };
  },

  watch: {
    $route: "fetchData",
    videoActive: function() {
      if (!this.videoActive) {
        // this.resetSupportForm();
        this.stopVideo();
      }
    }
  },

  methods: {

    openExternalLink(url) {
      window.open(url);
    },

    changeFaq(index) {
      if(index == this.activeFaq) {
        this.activeFaq = 999;
      } else {
        this.activeFaq = index;
      }

    },
  
    playVideo() {
      this.indexVideo.play();
      this.$store.commit("toggleModal");
      this.$store.commit("toggleVideo");
    },

    stopVideo() {
      this.indexVideo.pause();
      this.indexVideo.currentTime = 0;
    },

    closeEverything() {
      this.$store.commit("closeEverything");
    },

    validateForm(){
      this.validation.name            = this.form.name.length > 3 ? true : false;
      this.validation.role            = this.form.role ? true : false;
      this.validation.dealership_name = true;
      this.validation.dealership_code = true;
      this.validation.phone           = this.form.phone.length > 6 ? true : false;
      this.validation.email           = this.form.email.length > 6 ? true : false;
      this.validation.consent         = this.form.message.length > 6 ? true : false;

      return Object.values(this.validation).every(Boolean);
    },

    resetForm() {
      this.form.name = '';
      this.form.role = null;
      this.form.dealership_name = '';
      this.form.dealership_code = '';
      this.form.phone = '';
      this.form.email = '';
      this.form.message = '';
    },
  },

  computed: {

  },
}

</script>

<style scoped>

  h1 {
    font-size: 22px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 30px;
  }

  .faq-item ul {
    text-align: left;
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 30px;
    font-weight: 400;
  }

  .faq-item li {
    padding: 5px 0px 5px 0px; 
  }

  .btn-outline-primary {
    padding: 10px 10px 12px 10px;
    width: 100%;
    background-color:rgb(0, 124, 192);
    border: 1.4px solid white;
    font-weight: 400;
    border-radius: 30px;
    transition: none;
    color: white;
  }

  .btn-outline-primary:hover {
    background-color: rgb(23, 92, 142);
    color: white;
    transition: none;
  }

  .btn-outline-primary:active {
    background-color:rgba(0, 0, 0, .8) !important;
    color: white !important;
    transition: none;
  }

  .btn-outline-light {
    padding: 10px 10px 12px 10px;
    width: 100%;
    margin-top: 10px;
    background-color:rgba(0, 0, 0, .6);
    border: 1.4px solid white;
    font-weight: 400;
    border-radius: 30px;
    transition: none;
  }

  .btn-outline-light:hover {
    background-color:rgba(0, 0, 0, .8);
    color: white;
    transition: none;
  }

  .btn-outline-light:active {
    background-color:rgba(0, 0, 0, .8) !important;
    color: white !important;
    transition: none;
  }

  .faq-item a {
    color: #2d2d2d;
    max-width: 600px;
  }

  .faq-item a:hover {
    text-decoration: none;
    cursor: pointer;
    color: #5c88da;
  }

  .faq-item i:hover {
    color: rgb(23, 92, 142);
  }

  .faq-item-expanded {
    padding-top: 10px;
    padding-right: 23px;
    padding-bottom: 23px;
    padding-left: 22px;
  }

  .no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
    supported by Chrome and Opera */
  }

  .content {
    margin-top: 20px;
  }

  #close-x:hover {
    cursor: pointer;
    opacity: 1;
  }

  .photo {
    margin-right: 35px;
    padding: 10px;
    border-radius: 15px;
  }

  .content {
    margin-top: 20px;
  }

  .faq-chevron {
    position: absolute;
    width: 25px;
    height: 25px;
    margin-right: 15px;
    margin-left: -30px;
  }

  @media screen and (min-width: 1400px) {

    .fixed-ops-logo {
      max-width: 650px;
    }

    .checkbox-container {
      margin-left: 60px;
      margin-top: 30px;
    }

    .checkbox-container p {
      margin-top: -6px;
      margin-left: 20px;
    }

    .video {
      position: absolute;
      z-index: 250;
      background-color: black;
      border-radius: 5px;
    }

    .video:focus {
      outline: none;
    }

    #video-thumb:hover {
      opacity: 0.75;
      cursor: pointer;
    }

    .faq-item {
      max-width: 650px;
    }
  }

  @media screen and (max-width: 1399px) {

    #faq-container {
      max-width: 600px;
    }

    .checkbox-container {
      margin-left: 60px;
      margin-top: 30px;
    }

    .checkbox-container p {
      margin-top: -6px;
      margin-left: 20px;
    }

    .video {
      position: absolute;
      z-index: 250;
      background-color: black;
      border-radius: 5px;
    }

    .video:focus {
      outline: none;
    }

    #video-thumb:hover {
      opacity: 0.75;
      cursor: pointer;
    }

    .fixed-ops-logo {
      max-width: 650px;
    }
  }

  @media screen and (max-width: 768px) {

    .fixed-ops-logo {
      max-width: 100%;
    }

    .checkbox-container {
      margin-left: 10px;
      margin-top: 20px;
    }

    .checkbox-container p {
      margin-top: -10px;
      margin-left: 25px;
    }

    .content {
      margin-top: 0px;
      padding-top: 60px;
    }

    .photo {
      margin-right: 0px;
    }

    .video {
      position: fixed;
      right: 0px;
      border-radius: 0px;
      margin-top: 25%;
      z-index: 400;
    }

  }

</style>